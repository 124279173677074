#AssetManagerModal {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding: 8px;
  box-sizing: border-box;

  .FieldImageAssets-Title {
    padding: 8px;
  }
}
